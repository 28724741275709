import React, {useContext, useEffect, useRef, useState, useCallback} from "react";
import {
    checkIsValidId,
    checkIsValidKey,
    createEmptyScribEntry,
    generateReflectionId,
    IDType,
    Line,
    MIN_LINES_LENGTH,
    MIN_TEXT_LENGTH,
    Reflection,
    ScribEntry,
    seCanReflect,
    seText
} from "../../shared/types";
import styles from './dayview.module.css'
import {compressLines, decompressLines} from "../../shared/utils";
import Cookies from "js-cookie";
import ScribEntryDraw2, {ANIMATION_TIMEOUT} from "../scribentry2";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import { Reflectors } from "../reflectors";
import {Box, Fab, TextField} from "@mui/material";
import KeyboardIcon from '@mui/icons-material/Keyboard';
import DrawIcon from '@mui/icons-material/Draw';
import {useSimpleI18n} from "../../utils/i18n";
import DraggableClickable from "../draggableclickable";
import {TUT_3_CLASSNAME, TUT_4_CLASSNAME} from "../tutorial";
import { Refreshable } from "../../shared/utils/equi-scrib-internal-api";

const MIN_HEIGHT_WIDTH_FOR_PENCIL = 500;

export interface DayViewDetailProps {
    entryId: string
    onUpdatedOCR: (() => void) | undefined
}

export enum State {
    IDLING = 'Idling',
    LOADING_INITIAL_ENTRY = 'Loading initial entry',
    WAITING_FOR_SAVE_TIMEOUT = 'Waiting for save timeout',
    AUTO_SAVING = 'Auto saving',
    OCR_ING = 'Running OCR',
    EMOJIING = 'Getting EMOJI',
    REFLECTING = 'Reflecting',
    GENERATING_IMAGE_PROMPT = 'Generating Image Prompt',
    REFRESHING_IMAGE = 'Refreshing image',
    ENSURING_IDLE_FOR_PERIOD = 'Ensuring Idle For Period'
}

interface DayViewDetailState {
    lines: Line[],
    overriddenText: string,
    ocrdLines?: string;

    ocrText: string,
    ocrTextError?: Error,

    emojiText?: string,
    emojiTextError?: Error,

    psychologistText?: string|Error,
    imagePrompt?: string|Error,
    imageUrl?: string|Error
}

const defaultState = {
    lines: [],
    overriddenText: '',
    ocrdLines: undefined,
    ocrText: '',
}

function getDefaultDayViewPanelVisibility() {
    // read it from cookies, if not set return true
    return Cookies.get('writingPanelVisible') === 'true';
}

const DayViewDetail = (props: DayViewDetailProps) => {
    const scribPanel = useRef(undefined);
    const apiSignals = useRef([] as AbortController[])
    const [saveLifeCycle, setSaveLifeCycle] = useState(State.LOADING_INITIAL_ENTRY);
    const [state, setState] = useState<DayViewDetailState>(defaultState);
    const lastFetchedImageUrl = useRef('' as string|Error)
    const [isReadyToShowImage, setIsReadyToShowImage] = useState(false)
    const prevEntryId = useRef('')
    const otTimeout = useRef(undefined as any | undefined);
    const progressTimeout = useRef(undefined as any | undefined);
    const esApi = useContext(EsApiContext)
    const [displayableRows, setDisplayableRows] = useState(10);
    const [isPaused, setIsPaused] = useState(false);

    const [isAllowPencil, setIsAllowPencil] = useState(false);
    const [isWritingPanelVisible, setIsWritingPanelVisible] = useState(getDefaultDayViewPanelVisibility());

    const i18n = useSimpleI18n();

    useEffect(() => {
        if (state.imageUrl) {
            lastFetchedImageUrl.current = state.imageUrl;
        }
    }, [state.imageUrl]);


    checkIsValidId(props.entryId, IDType.CHILD)

    function getCancelToken(): AbortController {
        cancelPreviousAPIs();
        const cancelToken = esApi.getCancelToken();
        apiSignals.current.push(cancelToken);
        if (progressTimeout.current) {
            clearTimeout(progressTimeout.current)
            progressTimeout.current = undefined;
        }
        return cancelToken;
    }

    function resetCycle() {
        setState((prevState) => ({
            ...prevState,
            ocrdLines: '',
            ocrText: '',
            ocrTextError: undefined,
            emojiText: '',
            emojiTextError: undefined,
            psychologistText: '',
            imagePrompt: '',
            imageUrl: '',
        }))
    }

    async function saveOverrideText(newText: string) {
        setSaveLifeCycle(State.AUTO_SAVING)
        resetCycle();
        try {
            await esApi.updateScribEntryFieldOld(props.entryId, "overriddenText", newText, getCancelToken());
            progressStateIfIdleFor(2000);
        } catch (e: any) {
            if (e.name !== 'CanceledError') {
                console.log(`${e.name}: Failed to update: ${e}`);
            }
        }
    }

    async function saveLines(lines: Line[]) {
        const compressedLines = compressLines(lines);
        setSaveLifeCycle(State.AUTO_SAVING)
        resetCycle();
        try {
            console.log("Saving line");
            await esApi.updateScribEntryFieldOld(props.entryId, "lines", compressedLines, getCancelToken());
            console.log("Waiting three seconds for next state");
            progressStateIfIdleFor(3000);
        } catch (e: any) {
            if (e.name !== 'CanceledError') {
                console.log(`${e.name}: Failed to update: ${e}`);
            }
        }
    }


    function beginInitialEntryLoadProcess() {
        if (props.entryId && props.entryId === prevEntryId.current) {
            return;
        }
        prevEntryId.current = props.entryId;
        const entryRoot = props.entryId.split('.')[0];
        const subEntry = parseInt(props.entryId.split('.')[1]);
        // noinspection JSIgnoredPromiseFromCall
        loadInitialEntry(entryRoot, subEntry);
        setTimeout(() => {
            (scribPanel.current as any)?.updateCanvasSize();
            (scribPanel.current as any)?.redrawCanvas();
        }, ANIMATION_TIMEOUT)
    }


    function shouldOCR() {
        if (!state.lines || state.lines.length <= MIN_LINES_LENGTH || state.overriddenText) {
            return false;
        }
        if (state.ocrText || state.ocrTextError) {
            return false;
        }
        return state.ocrdLines !== compressLines(state.lines);
    }

    async function doOCR() {
        resetCycle();
        setSaveLifeCycle(State.OCR_ING);
        const compressedLines = compressLines(state.lines);
        try {
            const cancelToken = getCancelToken();
            console.log("Doing OCR");
            const se = await esApi.getScribEntryOcr(compressedLines, cancelToken);
            // save the ocrd lines
            await esApi.updateScribEntryFieldOld(props.entryId, "ocrText", se.ocrText, cancelToken);
            setState((prevState) => ({
                ...prevState,
                ocrdLines: compressedLines,
                ocrText: se.ocrText
            }))
            setSaveLifeCycle(State.IDLING);
        } catch (e: any) {
            if (e.name !== 'CanceledError') {
                console.log(`${e.name}: Failed to update: ${e}`);
            setState((prevState) => ({
                ...prevState,
                ocrTextError: e
            }))
            setSaveLifeCycle(State.IDLING);
            }
            // ignore
        }
    }

    function shouldEmoji(): boolean {
        return !state.emojiText && seCanReflect(state);
    }

    function progressStateIfIdleFor(number: number) {
        setSaveLifeCycle(State.ENSURING_IDLE_FOR_PERIOD);
        progressTimeout.current = setTimeout(() => setSaveLifeCycle(State.IDLING), number)
    }

    async function doEmoji() {
        setSaveLifeCycle(State.EMOJIING);
        try {
            const se = await esApi.getScribEntryEmoji(props.entryId, state, getCancelToken());
            setState((prevState) => ({
                ...prevState,
                emojiText: se.emojiUtf8
            }))
            props.onUpdatedOCR?.();
            progressStateIfIdleFor(1);
        } catch (e: any) {
            if (e.name !== 'CanceledError') {
                setState((prevState) => ({
                    ...prevState,
                    emojiTextError: e as Error
                }))
            }
        }
    }

    function shouldDoReflect() {
        return !!(state.ocrText || state.overriddenText) && !state.psychologistText;
    }

    function shouldDoNothing() {
        if (!state.overriddenText) {
            if (!state.lines) {
                return false;
            }
            return state.lines.length <= MIN_LINES_LENGTH;
        } else {
            // has override text, can move on (ie, do something)
            return false;
        }
    }

    function shouldGetImagePrompt() {
        return (state.ocrText || state.overriddenText) && state.psychologistText && !state.imagePrompt;
    }

    function shouldGetImage() {
        return (state.ocrText || state.overriddenText) && state.psychologistText && state.imagePrompt && !state.imageUrl;
    }

    function doReflect(force: boolean) {
        const stateUpdater = () => setState((prevState) => ({...prevState, psychologistText: undefined}));
        return doReflectGeneral(force, state.psychologistText, stateUpdater, State.REFLECTING,
            'text',
            (_key, cancelToken) => esApi.doReflect(state, cancelToken)
        );
    }

    function getImagePrompt(force: boolean) {
        const stateUpdater = () => setState((prevState) => ({
            ...prevState,
            imagePrompt: undefined,
            imageUrl: undefined
        }));
        return doReflectGeneral(force, state.imagePrompt, stateUpdater, State.GENERATING_IMAGE_PROMPT, 'imageAltText',
            (_key, cancelToken) => esApi.doImagePrompt(state, force, cancelToken)
        );
    }

    function getImage(force: boolean) {
        const stateUpdater = () => setState((prevState) => ({...prevState, imageUrl: undefined}));
        return doReflectGeneral(force, state.imageUrl, stateUpdater, State.REFRESHING_IMAGE, 'image',
            (_key, cancelToken) => {
                if (state.imagePrompt && typeof(state.imagePrompt) === 'string') {
                    const models = ['dall-e-3', 'dall-e-2', 'google']
                    // pick a random model
                    const randomModel = models[Math.floor(Math.random() * models.length)]
                    return esApi.doImage(
                        state,
                        state.imagePrompt,
                        false,
                        typeof(lastFetchedImageUrl.current) === 'string' ? lastFetchedImageUrl.current : '',
                        cancelToken,
                        randomModel)
                } else {
                    return undefined;
                }
            }
        );
    }

    async function doReflectGeneral(
        force: boolean,
        currentValue: string | undefined | Error,
        currentValueClearer: () => void,
        reflectState: State,
        reflectField: keyof Reflection,
        reflectGetter: (key: string, cancelToken: AbortController) => Promise<Partial<Reflection>> | undefined,
    ) {
        try {
            if (!force && currentValue && typeof (currentValue) === 'string') {
                return;
            }
            const seKey = generateReflectionId(seText(state));
            if (!checkIsValidKey(seKey, 16, 100, false)) {
                return;
            }
            const cancelToken = getCancelToken();
            setSaveLifeCycle(reflectState);
            let reflection: Partial<Reflection> | undefined;
            if (force) {
                // don't bother loading reflection, we want to force a refresh
                // hack for image prompt, when we set that, we'll also need to clear the image url
                if (reflectField === 'imageAltText') {
                    await esApi.updateReflectionField(props.entryId, seKey, 'image', '', cancelToken);
                }
            } else {
                // if we're not forcing, then we should get the reflection from the server if available
                reflection = await esApi.getReflection(seKey, cancelToken);
                // and we don't need to clear current values, since we don't want to force a refresh
                // currentValueClearer();
            }
            if (!reflection || !reflection[reflectField]) {
                // the field HAS changed.  Do clear its values
                currentValueClearer();
                reflection = await reflectGetter(seKey, cancelToken);
            }
            const value = reflection![reflectField] as string | undefined;
            if (value) {
                setState((prevState) => ({
                    ...prevState,
                    psychologistText: reflection!.text || prevState.psychologistText,
                    imagePrompt: reflection!.imageAltText || prevState.imagePrompt,
                    imageUrl: reflection!.image || prevState.imageUrl,
                }))
                // update the reflection if changed
                await esApi.updateReflectionField(props.entryId, seKey, reflectField, value, cancelToken);
                setSaveLifeCycle(State.IDLING);
                return;
            }
            setSaveLifeCycle(State.IDLING);
        } catch (e: any) {
            if (e.name !== 'CanceledError') {
                console.warn(`${e.name}: Failed to update: ${e}`);
                setState( (prevState) => ({
                    ...prevState,
                    psychologistText: reflectField === "text" ? e : prevState.psychologistText,
                    imagePrompt: reflectField === "imageAltText" ? e : prevState.imagePrompt,
                    imageUrl: reflectField === "image" ? e : prevState.imageUrl,
                }))
            }
            // ignore
        }
    }


    function progressSaveLifeCycle() {
        // noinspection JSIgnoredPromiseFromCall
        progressSaveLifeCycleAsync()
    }

    async function progressSaveLifeCycleAsync() {
        if (saveLifeCycle !== State.IDLING) {
            return; // wait for whatever state is ongoing to complete, and update item.
        }
        // at this point: there HAS to be a saved scrib entry
        if (shouldDoNothing()) {
            return;
        }
        if (shouldOCR()) {
            return doOCR();
        }
        if (shouldEmoji()) {
            return doEmoji();
        }
        if (isPaused) {
            return;
        }
        if (shouldDoReflect()) {
            return doReflect(false);
        }
        if (shouldGetImagePrompt()) {
            return getImagePrompt(false);
        }
        if (shouldGetImage()) {
            return getImage(false);
        }

        setIsReadyToShowImage(seCanReflect(state));
        console.log("No further progression required. Or possible.");
    }

    // on the props change, reload if entry changes
    useEffect(beginInitialEntryLoadProcess, [loadInitialEntry, props.entryId]);

    // when the text or lines change, pause the process
    useEffect(() => {
    }, [state.overriddenText, state.lines]);

    useEffect(progressSaveLifeCycle, [doEmoji, doOCR, doReflect, getImage, getImagePrompt, saveLifeCycle, shouldDoNothing, shouldDoReflect, shouldEmoji, shouldGetImage, shouldGetImagePrompt, shouldOCR, state])

    const debounce = (func: Function, wait: number) => {
        let timeout: NodeJS.Timeout;
        return (...args: any[]) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    const handleResize = useCallback(debounce((entries: ResizeObserverEntry[]) => {
        for (let entry of entries) {
            const ta = document.getElementById(`textarea-${props.entryId}`);
            if (ta) {
                const containerHeight = entry.contentRect.height;
                const computedStyle = window.getComputedStyle(ta);
                const paddingTop = parseFloat(computedStyle.paddingTop);
                const paddingBottom = parseFloat(computedStyle.paddingBottom);
                const lineHeight = parseFloat(computedStyle.lineHeight);
                
                const availableHeight = containerHeight - paddingTop - paddingBottom;
                const rows = Math.max(3, Math.floor(availableHeight / lineHeight) - 1); // Subtract 1 as a buffer
                
                setDisplayableRows(rows);
            }
            
            const isWideForm = window.innerWidth > MIN_HEIGHT_WIDTH_FOR_PENCIL && window.innerHeight > MIN_HEIGHT_WIDTH_FOR_PENCIL;
            if (!isWideForm) {
                if (hasHandWrittenNotes(state)) {
                    setIsAllowPencil(true);
                } else {
                    setIsAllowPencil(false);
                    setIsWritingPanelVisible(false);
                }
            } else {
                setIsAllowPencil(true);
            }
        }
    }, 100), [props.entryId, state]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize);

        const container = document.getElementById(`textarea-${props.entryId}`)?.parentElement?.parentElement;
        if (container) {
            resizeObserver.observe(container);
        }

        return () => {
            if (container) {
                resizeObserver.unobserve(container);
            }
        };
    }, [props.entryId, handleResize]);


    async function updateStateFromEntries(entries: ScribEntry[], entryIdRoot: string, displayedEntry: number, reflect: Reflection | undefined) {
        const entry = entries[displayedEntry] as ScribEntry || createEmptyScribEntry(entryIdRoot, displayedEntry)
        const decompressedLines = decompressLines(entry.lines);
        setState((_prevState) => {
            const newState: DayViewDetailState = {
                lines: decompressedLines,
                overriddenText: entry.overriddenText,
                emojiText: entry.emojiUtf8,
                ocrText: entry.ocrText,
                imagePrompt: reflect?.imageAltText,
                psychologistText: reflect?.text,
                imageUrl: reflect?.image,
            }
            return newState;
        });
        if (entry.overriddenText) {
            setIsWritingPanelVisible(false);
        }
    }

    async function loadInitialEntry(entryId: string, displayedEntry: number) {
        setSaveLifeCycle(State.LOADING_INITIAL_ENTRY)
        let ses;
        try {
            ses = await esApi.getScribEntries(entryId)
        } catch (e) {
            throw e;
        }
        const se = ses[displayedEntry] as ScribEntry || createEmptyScribEntry(entryId, displayedEntry)
        const reflectId = generateReflectionId(seText(se))
        let reflect;
        if (reflectId && reflectId.length > 10) {
            reflect = await esApi.getReflection(reflectId);
        }
        await updateStateFromEntries(ses, entryId, displayedEntry, reflect)
        setSaveLifeCycle(State.IDLING)
    }


    function togglePanel() {
        setIsWritingPanelVisible(!isWritingPanelVisible);
        Cookies.set('writingPanelVisible', isWritingPanelVisible ? 'false' : 'true', {expires: new Date(2099, 12, 31)});
    }

    function tpClassNames(visible: boolean): string {
        return styles.panel + ' ' + (!visible ? 'flip-in' : 'hidden');
    }

    function tpDisplay(visible: boolean): string {
        return visible ? 'none' : 'flex';
    }

    function wpClassNames(visible: boolean): string {
        return styles.panel + ' ' + (visible ? 'flip-in' : 'hidden');
    }

    async function linesChanged(_props: DayViewDetailProps, newLines: Line[]) {
        setState((prevState) => ({
            ...prevState,
            lines: newLines,
        }))
        setIsPaused(true);
        saveLines(newLines).then(() => {
        }); // empty block to avoid warning
    }


    function updateOverrideText(newText: string) {
        setIsPaused(true);
        resetCycle()
        setState((prevState) => ({
            ...prevState,
            overriddenText: newText,
        }))
        setSaveLifeCycle(State.WAITING_FOR_SAVE_TIMEOUT);
        if (otTimeout.current) {
            clearTimeout(otTimeout.current);
        }
        cancelPreviousAPIs()
        otTimeout.current = setTimeout(() => saveOverrideText(newText), 1000);
    }

    function cancelPreviousAPIs() {
        for (const signal of apiSignals.current) {
            try {
                signal.abort("Cancelling previous save");
            } catch (e) {
                // ignore this.
            }
        }
        apiSignals.current = [];
    }


    function moreChangesComing() {
        cancelPreviousAPIs();
        setSaveLifeCycle(State.WAITING_FOR_SAVE_TIMEOUT);
    }

    function resume() {
        setIsPaused(false);
        progressSaveLifeCycle
    }

    async function refreshImage(type: Refreshable) {
        switch (type) {
            case Refreshable.IMAGE_URL:
                return getImage(true);
            case Refreshable.IMAGE_PROMPT:
                return getImagePrompt(true);
            case Refreshable.PSYCHOLOGIST_TEXT:
                return doReflect(true);
        }
    }

    return (
        <div style={{width: '100%', position: 'relative'}}>
            <div className={styles.loadingOverlay}
                 style={saveLifeCycle !== State.LOADING_INITIAL_ENTRY ? {display: 'none'} : {}}>
                <span>{i18n("Loading...")}</span>
            </div>
            {isAllowPencil && (<DraggableClickable onClick={togglePanel}>
                <Fab disableRipple={true} color="primary" aria-label="toggle"
                     className={styles.fabButton + ' ' + TUT_4_CLASSNAME}>
                    {isWritingPanelVisible ? <KeyboardIcon data-testid={'activate-type-button'}/> :
                        <DrawIcon data-testid={'activate-draw-button'}/>}
                </Fab>
            </DraggableClickable>)}
            <div className={TUT_3_CLASSNAME} style={{display: 'flex'}}>
                <div id="writingPanel" className={wpClassNames(isWritingPanelVisible)}>
                    <ScribEntryDraw2
                        skipRedraws={!isWritingPanelVisible}
                        id={`scribpanel-${props.entryId}`}
                        key={`scribpanel-${props.entryId}`}
                        style={{width: '100%', height: '100%'}}
                        ref={scribPanel}
                        lines={state.lines}
                        onLinesChanged={(newLines, _otherMsg: string) => {
                            // noinspection JSIgnoredPromiseFromCall
                            linesChanged(props, newLines);
                        }}
                        onPendingChanges={moreChangesComing}
                    />
                </div>
                <div id="textPanel" className={tpClassNames(isWritingPanelVisible)}
                     style={{display: tpDisplay(isWritingPanelVisible)}}>
                    <TextField
                        id={`textarea-${props.entryId}`}
                        key={`textarea-${props.entryId}`}
                        onChange={(ot) => {
                            updateOverrideText(ot.target.value)
                        }}
                        placeholder={i18n("Please share your current view and feelings in 100 or more characters.\nWhat do you see? How do you feel?")}
                        multiline={true}
                        rows={displayableRows}
                        fullWidth={true}
                        variant={"outlined"}
                        value={state.overriddenText || state.ocrText}
                        helperText={`${(state.overriddenText || state.ocrText || '').length}/${MIN_TEXT_LENGTH}`}
                        FormHelperTextProps={{
                            style: { textAlign: 'right', color: (state.overriddenText || state.ocrText || '').length < MIN_TEXT_LENGTH ? 'red' : 'black' }
                        }}
                    />
                </div>
            </div>

            {/* Second Row */}
            <Reflectors lines={state.lines}
                        overrideText={state.overriddenText}
                        scribEntryId={props.entryId}
                        ocrText={state.ocrText || state.ocrTextError}
                        hasOcrd={state.ocrdLines === compressLines(state.lines)}
                        imageUrl={state.imageUrl}
                        imagePrompt={state.imagePrompt}
                        emoji={state.emojiText || state.emojiTextError}
                        psychologistText={state.psychologistText}
                        state={saveLifeCycle}
                        isPaused={isPaused}
                        showFinalImage={isReadyToShowImage}
                        onRefresh={refreshImage}
                        onResume={resume}
            />
        </div>
    )
};

export default DayViewDetail;
function hasHandWrittenNotes(state: DayViewDetailState) {
    return state.lines?.length > 10 || state.ocrText || state.ocrdLines;
}
