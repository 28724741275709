import {useIntl} from "react-intl";
import ReactDOMServer from 'react-dom/server';
import {useContext} from "react";
import {EsApiContext} from "./equi-scrib-internal-api-context";
import {to18nKey} from "../shared/utils/strings";
import {IS_DEV} from "../shared/utils/firebase-client";

function replaceKeys(defaultMessage: string, args?: any) {
    if (args) {
        let msg = defaultMessage;
        for (const key in args) {
            msg = msg.replace("{" + key + "}", args[key]);
        }
        return msg;
    }
    return defaultMessage;
}

// Custom hook that returns a simplified translation function
export const useSimpleI18n = () => {
    const intl = useIntl();
    const esApi = useContext(EsApiContext);
    // Function that takes a default message and returns a translated string
    return (defaultMessage: string, args?: any) => {
        if (!defaultMessage) {
            return "";
        }
        const safeKey = to18nKey(defaultMessage);
        if (!safeKey) {
            return replaceKeys(defaultMessage, args);
        }

        // save the missing key to the server if missing
        if (!intl.messages[safeKey]) {
            if (IS_DEV) {
                console.warn("Missing key: " + safeKey);
                esApi.postMissingKey(defaultMessage);
            }
            return replaceKeys(defaultMessage, args);
        }
        return replaceKeys(
            intl.formatMessage({id: safeKey, defaultMessage}),
            args
        );
    }
};

export const SimpleI18NMessage = ({msg}: { msg: string }) => {
    const i18n = useSimpleI18n();
    return <>{i18n(msg)}</>
}
// @ts-ignore
export const I18N = ({children}) => {
    const i18n = useSimpleI18n();
    // if the children are a simple text note, then use the SimpleI18NMessage component
    if (typeof children === 'string') {
        return <SimpleI18NMessage msg={children}/>
    }
    // get the inner HTML text of the children, and translate that.  This allows for HTML tags to be used in the translation
    // for simple bolding
    const innerHtml = ReactDOMServer.renderToStaticMarkup(children);
    const translated = i18n(innerHtml);
    return <span dangerouslySetInnerHTML={{__html: translated}}/>
}
