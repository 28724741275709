import {checksum, shortChecksum} from "./index";

export function containsAnyOf(s: string | undefined, strings: string[]) {
    if (!s) {
        return false;
    }
    for (const string of strings) {
        if (s.includes(string)) {
            return true;
        }
    }
    return false;
}

export function to18nKey(s: string) {
    // remove from defaultMessage any character that can't be in a Json key
    if (!s) {
        debugger
        return '';
    }
    let safeKey = s.trim().replace(/[^a-zA-Z0-9 ]/g, '');
    if (safeKey.length > 50 || safeKey !== s) {
        safeKey = safeKey.substring(0, 50) +  /* a simple hash */ shortChecksum(s);
    }
    return safeKey;
}

export function toKey(s: string) {
    const lcCharOnly = s?.toLowerCase()?.replaceAll(/[^a-z0-9]+/g, '');
    if (!lcCharOnly) {
        return '';
    }
    return checksum(s);
}