import {Button, Table, TableBody, TableCell, TableRow,} from "@mui/material";
import styles from './styles.module.css'
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {EsApiContext} from "../../../utils/equi-scrib-internal-api-context";
import LoadingOverlay from "../../loadingoverlay";
import {UserSettings} from "../../../shared/types";
import PromptPassword from "./promptpassword";
import PromptConversionStartDialog from "./prompstartconversiondialog";
import PromptConversionResumeDialog from "./promptresumeconversiondialog";
import DataConversionDialog, {ConversionSettings} from "./dataconversiondialog";
import PasswordField from "../../passwordfield";
import {I18N} from "../../../utils/i18n";


function forceFullRefresh() {
    setTimeout(() => {
        window.location.reload();
    }, 100);
}

const SecuritySettingsPage = () => {
    const esApi = useContext(EsApiContext);
    const [isLoading, setIsLoading] = useState(true);
    const [displayResumeConversionWarning, setDisplayResumeConversionWarning] = useState(false);
    const [displayPromptCurrentPassword, setDisplayPromptCurrentPassword] = useState(false);
    const [displayPromptNewPassword, setDisplayPromptNewPassword] = useState(false);
    const [conversionSettings, setConversionSettings] = useState(undefined as ConversionSettings | undefined);
    const [trueE2eKey, setTrueE2eKeyTmp] = useState('');
    const [lastSavedPassword, setLastSavedPassword] = useState('');
    const settingsRef = useRef(undefined as UserSettings | undefined);

    const setTrueE2eKey = (key: string) => {
        setTrueE2eKeyTmp(key);
    }

    // load initial settings/state on startup
    const loadInitialSettings = useCallback(async () => {
        setDisplayResumeConversionWarning(false);
        setDisplayPromptCurrentPassword(false);
        setDisplayPromptNewPassword(false);
        setIsLoading(true);
        settingsRef.current = undefined
        const settings = await esApi.getSettings();
        settingsRef.current = settings;
        const settingsPassword = await esApi.getClientE2eKey(settings.trueE2eKeyVersion);
        const settingsNewPassword = await esApi.getClientE2eKey(settings.newTrueE2eKeyVersion);
        setLastSavedPassword(settingsPassword || '');
        if (settings.trueE2eKeyVersion && !settingsPassword) {
            setDisplayPromptCurrentPassword(true);
        }
        if (settings.newTrueE2eKeyVersion && !settingsNewPassword) {
            setDisplayPromptNewPassword(true);
        }
        if (settings.newPseudoE2eKey || settings.newPseudoE2eKey || settings.newTrueE2eKeyVersion) {
            // there is an incomplete conversion.  Resume it.
            setDisplayResumeConversionWarning(true);
            if (!!settings.trueE2eKeyVersion) {
                setTrueE2eKey(settingsNewPassword || '');
            } else {
                setTrueE2eKey(settingsPassword || '');
            }
        } else {
            // no pending conversion
            setTrueE2eKey(settingsPassword || '');
        }
        setIsLoading(false);
    }, [esApi])
    useEffect(() => {
        loadInitialSettings().then(() => {
        });
    }, [loadInitialSettings]);

    const getTrueE2eKeyVersion = useCallback(async (trueE2ePassword: string | undefined): Promise<number> => {
        if (!trueE2ePassword) {
            return 0;
        }
        // we have a current version.
        if (await (esApi.getClientE2eKey(settingsRef.current?.trueE2eKeyVersion)) === trueE2ePassword) {
            // it is the same, so return that version
            return settingsRef.current!.trueE2eKeyVersion!;
        }
        if (await (esApi.getClientE2eKey(settingsRef.current?.newTrueE2eKeyVersion)) === trueE2ePassword) {
            // there is no current password, so return that version number
            return settingsRef.current!.newTrueE2eKeyVersion!;
        }
        // the password we're using is not the current one, nor the new one.  So we need to create a new version
        return Math.max(
            settingsRef.current?.trueE2eKeyVersion || 0,
            settingsRef.current?.newTrueE2eKeyVersion || 0
        ) + 1;
    }, [esApi])


    async function startConversion() {
        setIsLoading(true);
        // we just need to save the settings, and resume the conversion
        const e2eVersion = await getTrueE2eKeyVersion(trueE2eKey);
        // save settings
        const newSettings = {
            ...settingsRef.current,
            newPseudoE2eKeyVersion: settingsRef.current?.pseudoE2eKeyVersion,
            newPseudoE2eKey: settingsRef.current?.pseudoE2eKey,
            newTrueE2eKeyVersion: e2eVersion,
        } as UserSettings
        settingsRef.current = newSettings;
        if (e2eVersion) {
            await esApi.updateClientE2eKey(trueE2eKey, e2eVersion)
        }
        await esApi.updateSettings(newSettings);
        setIsLoading(false);
        continueConversion().then(() => {
        })
    }

    async function continueConversion() {
        const newTrueE2eKeyVersion = settingsRef.current?.newTrueE2eKeyVersion
        setDisplayResumeConversionWarning(false)
        let newPassword = ''
        if (newTrueE2eKeyVersion) {
            newPassword = (await esApi.getClientE2eKey(newTrueE2eKeyVersion))!
            setTrueE2eKey(newPassword)
        }
        const conversionSettingsNew = {
            pseudoE2eKeyFrom: settingsRef.current?.pseudoE2eKey,
            pseudoE2eKeyVersionFrom: settingsRef.current?.pseudoE2eKeyVersion,
            trueE2eKeyFrom: await (esApi.getClientE2eKey(settingsRef.current?.trueE2eKeyVersion)),
            trueE2eKeyVersionFrom: settingsRef.current?.trueE2eKeyVersion,
            pseudoE2eKeyTo: settingsRef.current?.newPseudoE2eKey,
            pseudoE2eKeyVersionTo: settingsRef.current?.newPseudoE2eKeyVersion,
            trueE2eKeyTo: newPassword,
            trueE2eKeyVersionTo: newTrueE2eKeyVersion
        } as ConversionSettings
        setConversionSettings(conversionSettingsNew);
    }

    // not sure why state variable e2eEncryption has a stale value sometimes.  So using the callback version
    // for those cases where this happened.
    const saveSettings2 = useCallback(async (trueE2eKey: string) => {
        setIsLoading(true);
        const currentSettings = await esApi.getSettings();
        const e2eVersion = await getTrueE2eKeyVersion(trueE2eKey);
        // save settings
        const newSettings = {
            ...currentSettings,
            pseudoE2eKeyVersion: settingsRef.current?.pseudoE2eKeyVersion,
            trueE2eKeyVersion: e2eVersion,
            newPseudoE2eKey: undefined,
            newTrueE2eKeyVersion: undefined,
            newPseudoE2eKeyVersion: undefined
        } as UserSettings
        if (e2eVersion) {
            await esApi.updateClientE2eKey(trueE2eKey, e2eVersion)
        }
        await esApi.updateSettings(newSettings);
        setIsLoading(false);
        loadInitialSettings().then(() => {
        });
    }, [esApi, getTrueE2eKeyVersion, loadInitialSettings])

    const saveSettings = useCallback(async () => {
        await saveSettings2(trueE2eKey);
    }, [saveSettings2, trueE2eKey]);

    if (isLoading) {
        return (
            <LoadingOverlay/>
        )
    }

    function showCmpScreen() {
        // @ts-ignore
        __cmp('showScreenAdvanced')
    }

    return (
        <div className={styles.main}>
            { /* https://stackoverflow.com/questions/2530/how-do-you-disable-browser-autocomplete-on-web-form-field-input-tags
               prevent password auto-fill */}
            <input type="text" style={{display: 'none'}}/>
            <input type="password" style={{display: 'none'}}/>


            <PromptConversionResumeDialog
                open={displayResumeConversionWarning}
                onResume={() => continueConversion()}
                onIgnore={() => saveSettings()}
            />

            {conversionSettings && (<DataConversionDialog
                conversionSettings={conversionSettings}
                onFinish={(_completedSuccessfully) => {
                    setConversionSettings(undefined)
                    // force reload current page
                    window.location.reload();
                }}
            />)}

            <PromptPassword
                missingPassword={displayPromptCurrentPassword}
                missingNewPassword={displayPromptNewPassword}
                knownSettings={settingsRef.current!}
                onCurrentPasswordSet={async (password: string) => {
                    await esApi.updateClientE2eKey(password, settingsRef.current?.trueE2eKeyVersion!)
                    setTrueE2eKey(password);
                    setDisplayPromptCurrentPassword(false);
                    if (!displayPromptNewPassword) {
                        setLastSavedPassword(password);
                        // force a full refresh (potentially cached keys) in 100ms
                        forceFullRefresh();
                    }
                }}
                onNewPasswordSet={async (password: string) => {
                    await esApi.updateClientE2eKey(password, settingsRef.current?.newTrueE2eKeyVersion!)
                    setTrueE2eKey(password);
                    setDisplayPromptNewPassword(false);
                    setLastSavedPassword(password);
                    // force a full refresh (potentially cached keys) in 100ms
                    forceFullRefresh();
                }}
                onTurnOffE2e={() => {
                    setTrueE2eKey('')
                    saveSettings2('').then(() => {
                    })
                }}
            />


            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell><h3><I18N>End-to-End Encryption</I18N></h3></TableCell>
                        <TableCell>
                            <div className={styles.radioGroup}>
                                <p><I18N>
                                    EquiQuill will always securely password protect your data whether you set a
                                    password here or not.
                                </I18N></p>
                                <p><I18N>
                                    For added security, you may further protect your journal entries using a
                                    password that only you know, and that is never stored on our servers.
                                </I18N></p>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <PasswordField
                                        id={"trueE2eKey" + new Date().getTime()}
                                        name={"trueE2eKey" + new Date().getTime()}
                                        value={trueE2eKey}
                                        onChange={(e) => setTrueE2eKey(e.target.value)}
                                    />
                                </div>
                                <p><I18N>
                                    End-to-End encryption applies to journal entries stored in our system.
                                    AI augmented features such as OCR, emoji, reflection, and image generation
                                    use standard TSL/SSL secure transmission protocols
                                </I18N></p>
                                <div>&nbsp;</div>
                                <PromptConversionStartDialog
                                    oldPassword={lastSavedPassword}
                                    newPassword={trueE2eKey}
                                    onRevert={() => loadInitialSettings()}
                                    onStart={startConversion}
                                    onIgnore={saveSettings}
                                />


                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><h3>Cookies and Privacy</h3></TableCell>
                        <TableCell>
                            <div className={styles.radioGroup}>
                                <p><I18N>
                                    We use cookies to provide you with a secure and the best possible experience on our website.
                                    </I18N></p><p><I18N>
                                    You may re-open the cookie preferences banner and re-configure your cookie settings by clicking
                                    the button below.
                                </I18N></p>
                                <Button onClick={showCmpScreen}>
                                    Configure Cookie Privacy Settings
                                </Button>
                            </div>
                        </TableCell>
                    </TableRow>
                    {/*<TableRow>*/}
                    {/*    <TableCell><h3>Advanced Services Consent</h3></TableCell>*/}
                    {/*    <TableCell>*/}
                    {/*        <div className={styles.radioGroup}>*/}
                    {/*        <FormControlLabel value="true" control={<Checkbox/>}*/}
                    {/*                              label="Use AI augmented features"/>*/}
                    {/*            {trueE2eKey &&*/}
                    {/*                <p>*/}
                    {/*                    AI augmented features include advanced features such as OCR, emoji,*/}
                    {/*                    reflection, and image generation. When using advanced features, your*/}
                    {/*                    data will be sent encrypted, without your password and without*/}
                    {/*                    end-to-end encryption protection, to AI services hosted by trusted*/}
                    {/*                    providers such as Google and OpenAI*/}
                    {/*                </p>*/}
                    {/*            }*/}
                    {/*            {!trueE2eKey &&*/}
                    {/*                <p>*/}
                    {/*                    AI augmented features include advanced features such as OCR, emoji,*/}
                    {/*                    reflection, and image generation. When using advanced features, your*/}
                    {/*                    data will be sent encrypted, without end-to-end encryption protection,*/}
                    {/*                    to AI services hosted by trusted providers such as Google and OpenAI*/}
                    {/*                </p>*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </TableCell>*/}
                    {/*</TableRow>*/}
                </TableBody>
            </Table>
        </div>
    )
}

export default SecuritySettingsPage;
