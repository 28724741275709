import React, {useContext, useEffect, useState} from "react";
import ESInfiniView from "../esinfiniview";
import ESCal from "../escal";
import styles from './styles.module.css';
import IconButton from "@mui/material/IconButton";
import {useSimpleI18n} from "../../utils/i18n";
import DraggableClickable from "../draggableclickable";
import {Fab} from "@mui/material";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import PersonaSelectionMatrix from "../personaselection";
import {Character} from "../../shared/types";

const DayOrMonthView = () => {
    const [isDayView, setIsDayView] = useState(true);

    const i18n = useSimpleI18n();

    const [personaSelectCharacter, setPersonaSelectCharacter] = useState(undefined as Character | undefined);
    const esApi = useContext(EsApiContext);

    async function checkShowPersona() {
        const settings = await esApi.getSettings();
        if (!settings.characters || settings.characters.length === 0 || !settings.characters[0].description) {
            const newCharacter = settings.characters[0] || {name: 'author, narrator', description: '', url: ''};
            setPersonaSelectCharacter(newCharacter);
        }
    }


    async function savePersonaUpdate(finalChar: Character) {
        let oldSettings = await esApi.getSettings();
        if (!oldSettings.characters || oldSettings.characters.length === 0) {
            oldSettings  = {...oldSettings, characters: []};
        }
        oldSettings.characters[0] = finalChar;
        await esApi.updateSettings(oldSettings);
        setPersonaSelectCharacter(undefined);
    }

    useEffect(() => {checkShowPersona().then()}, [esApi]);

    return (
        <div className={styles.main}>
            {personaSelectCharacter && <PersonaSelectionMatrix isPrompt0={true} character={personaSelectCharacter!} onComplete={savePersonaUpdate}/>}
            {isDayView && (
                <DraggableClickable onClick={() => setIsDayView(false)}>
                <IconButton title={i18n("Month View")}
                            style={{right: '40px'}}
                >
                    <img src='/MonthView.png' style={{width: '32px', height: '32px'}} draggable={false}/>
                </IconButton>
                </DraggableClickable>
            )}
            {!isDayView && (
                <DraggableClickable onClick={() => setIsDayView(true)}>
                    <Fab>
                        <IconButton title={i18n("Day View")} style={{right: '16px'}}>
                            <img src='/DayView.png' style={{width: '32px', height: '32px'}} draggable={false}/>
                        </IconButton>
                    </Fab>
                </DraggableClickable>
            )}
            <div style={{position: 'relative'}}>
            {isDayView && <ESInfiniView/>}
            {!isDayView && <ESCal/>}
            </div>
        </div>
    )

}

export default DayOrMonthView;