import React, {useContext} from "react";
import styles from './styles.module.css'
import {Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup} from "@mui/material";
import Button from "@mui/material/Button";
import {
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    PinterestIcon,
    PinterestShareButton,
    TumblrIcon,
    TumblrShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';
import {newId} from "../../shared/utils";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import {AES} from "crypto-js";
import {useSnackbar} from "../globalsnackbar";

interface ShareDialogProps {
    onClose?: () => void
    open: boolean
    scribEntryId: string,
    imageUrl: string|undefined
    reflection: string|undefined
}

interface AutoShareButtonProps {
    Icon: any
    Button: any
}



export const ShareDialog = (props: ShareDialogProps) => {

    const [shareImage, setShareImage] = React.useState(true)
    const [shareReflection, setShareReflection] = React.useState(true)

    const [entryId, setEntryId] = React.useState<string>(newId)
    const [pwd, setPwd] = React.useState<string>(newId)
    const esApi = useContext(EsApiContext)

    const snackBar = useSnackbar()
    const urlLocalOrDeployed = window.location.origin + '/#/sharedentries/' + entryId + pwd
    const urlDeployed = 'https://app.equiquill.com/#/sharedentries/' + entryId + pwd

    function closeDialog() {
        if (props.onClose) {
            props.onClose()
        }
    }

    async function finalizeLink() {
        let encryptedImageUrl: string = ''
        let encryptedReflection: string = ''
        if (shareImage && props.imageUrl) {
            encryptedImageUrl = AES.encrypt(props.imageUrl, pwd).toString()
        }
        if (shareReflection && props.reflection) {
            encryptedReflection = AES.encrypt(props.reflection, pwd).toString()
        }
        await esApi.shareEntry(entryId, props.scribEntryId, encryptedImageUrl, encryptedReflection)
        console.log(`Link ${urlLocalOrDeployed} created.`);
    }

const CopyButton = (props: {children: React.ReactNode}) => {
        return (
            <Button onClick={async () => {
                await finalizeLink()
                await navigator.clipboard.writeText(urlLocalOrDeployed)
                snackBar.displayMessage('Link copied to clipboard')
            }}>
                {props.children}
            </Button>
        )
    }

    return (
        <Dialog open={props.open} onClose={closeDialog} className={styles.shareDialog}>
            <DialogTitle>
                {shareReflection && shareImage && <>Share Image and Reflection</>}
                {shareReflection && !shareImage && <>Share Reflection</>}
                {!shareReflection && shareImage && <>Share Image</>}
                {!shareReflection && !shareImage && <>Share Nothing!</>}
            </DialogTitle>
            <DialogContent>
                <div className={styles.shareWhat}>
                    <div className={styles.sharePreview}>
                        <div>
                            {shareImage && <img width={128} height={128} src={props.imageUrl}/>}
                        </div>
                        <div>{shareReflection ? props.reflection : ''}</div>
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={shareImage} onChange={() => setShareImage(!shareImage)}/>}
                            label="Share Image"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={shareReflection}
                                               onChange={() => setShareReflection(!shareReflection)}/>}
                            label="Share Reflection"
                        />
                    </FormGroup>

                </div>
                <div className={styles.shareHow}>
                    <FacebookShareButton disabled={!props.imageUrl && !props.reflection} url={urlDeployed} beforeOnClick={finalizeLink}>
                        <FacebookIcon size={32} round/>
                    </FacebookShareButton>
                    
                    <TumblrShareButton disabled={!props.imageUrl && !props.reflection} url={urlDeployed} beforeOnClick={finalizeLink}>
                        <TumblrIcon size={32} round/>
                    </TumblrShareButton>
                    
                    <PinterestShareButton disabled={!props.imageUrl} url={urlDeployed} beforeOnClick={finalizeLink} media={props.imageUrl!}>
                        <PinterestIcon size={32} round/>
                    </PinterestShareButton>

                    <WhatsappShareButton url={urlDeployed} beforeOnClick={finalizeLink} disabled={!props.imageUrl && !props.reflection}>
                        <WhatsappIcon size={32} round/>
                    </WhatsappShareButton>
                    
                    <EmailShareButton url={urlDeployed} beforeOnClick={async () => {
                        await finalizeLink()
                        // also copy link to clipboard.
                        await navigator.clipboard.writeText(urlLocalOrDeployed)
                    }}
                                      subject={"I'm feeling..."}
                                      body={`See my journal entry to found out more.  Follow this link\n${urlLocalOrDeployed}`}
                    >
                        <EmailIcon/>
                    </EmailShareButton>
                    
                    <CopyButton>
                        <LinkIcon/>
                    </CopyButton>
                </div>

                <div className={styles.sharePrivacyNote}>
                    <div className={styles.sharePrivacyNoteTitle}>Note on privacy</div>
                    <div className={styles.sharePrivacyNoteNote}>
                    Your journal entries are always encrypted. When shared, a secure, one-time link grants access
                        only
                        to those who have it. We do not store these links to ensure your privacy.
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )

}
