import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import Papa from 'papaparse';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import {Character} from '../../shared/types';
import {useSimpleI18n} from '../../utils/i18n';
import styles from './styles.module.css';
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

interface PersonaSelectionMatrixProps {
    isPrompt0: boolean;
    character: Character;
    onComplete: (finalChar: Character) => void;
}

interface Persona {
    Real: boolean,
    Description: string
    url: string
    Custom?: boolean
}

export const PersonaSelectionMatrix: React.FC<PersonaSelectionMatrixProps> = ({character, isPrompt0, onComplete}) => {
    const originalCharacter = character;
    const i18n = useSimpleI18n();
    const containerRef = useRef<HTMLDivElement>(null);
    const esApi = useContext(EsApiContext);

    const [gridSize, setGridSize] = useState(5);
    const [customDescription, setCustomDescription] = useState(character.description || '');
    const [characterName, setCharacterName] = useState(character.name || '');
    const [personas, setPersonas] = useState<Persona[]>([]);
    const [generatingPreview, setGeneratingPreview] = useState(false);
    const [error, setError] = useState('');

    async function updatePersonas(csv: any) {
        const result = Papa.parse(csv, {header: true});
        const ps: Persona[] = result.data.map((row: any, idx: number) => ({
            Real: row.Real === 1,
            Description: row.Description,
            Custom: false,
            url: `persona_${idx < 9 ? "0" : ""}${idx + 1}.png`
        }));
        // shuffle all items
        for (let i = ps.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [ps[i], ps[j]] = [ps[j], ps[i]];
        }
        const foundDescription = ps.find((persona) => normalizeString(persona.Description) === normalizeString(originalCharacter.description));
        if (originalCharacter.description && !foundDescription) {
            let previewUrl = '';
            try {
                previewUrl = await esApi.doPersonaPreview(originalCharacter.description);
            } catch (e: any) {
                setError(e.response?.data || 'An error occurred. Please try refreshing2.');
            }
            const toAdd = {Real: false, Description: originalCharacter.description, Custom: true, url: previewUrl};
            ps.unshift(toAdd);
            console.log('added custom description', toAdd);
        }
        if (foundDescription) {
            // make sure it is first
            ps.splice(ps.indexOf(foundDescription), 1);
            ps.unshift(foundDescription);
        }

        setPersonas(ps);
        setGridSize(updateGridSize(window.innerWidth));
    }

    useEffect(() => {
        fetch('/personas.csv')
            .then(response => response.text())
            .then(csv => updatePersonas(csv));
    }, []);

    useEffect(() => {
        if (!customDescription) {
            return;
        }
        const p = personas.find((persona) =>
            normalizeString(persona.Description) === normalizeString(customDescription)
        );
        if (p) {
            // do nothing
            return
        }
        // remove the custom personal if present
        const newPs = personas.filter((persona) => !persona.Custom);
        newPs.unshift({ Real: false, Description: customDescription, Custom: true, url: '' });
        setPersonas(newPs);
    }, [customDescription, personas]);

    async function addCustomDescriptionToPersonas() {
        if (error) {
            return;
        }
        setGeneratingPreview(true);
        let previewUrl = '';
       try {
           previewUrl = await esApi.doPersonaPreview(customDescription);
       } catch (e: any) {
            setError(e.response?.data || 'An error occurred. Please try refreshing.');
       }
        setGeneratingPreview(false);
        const newPersona = { Real: false, Description: customDescription, Custom: true, url: previewUrl };
        const newPersonas = [...personas];
        // if first element is custom, remove it.
        if (newPersonas[0].Custom) {
            newPersonas.shift();
        }
        // add our newPersona at 0
        newPersonas.unshift(newPersona);
        setPersonas(newPersonas);
    }

    const handleGenerateImage = async () => {
        addCustomDescriptionToPersonas();
    };

    const updateGridSize = (width: number) => {
        if (width < 400) return 3;
        if (width < 500) return 4;
        return 5;
    };

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                const newGridSize = updateGridSize(containerRef.current.clientWidth);
                setGridSize(newGridSize);
            }
        };

        const observer = new ResizeObserver(handleResize);
        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => observer.disconnect();
    }, []);

    const normalizeString = (str: string) => {
        return str?.toLowerCase()?.replace(/[^a-z0-9]/g, '');
    };

    const isSelected = useMemo(() => {
        const normalizedCustom = normalizeString(customDescription);
        return (description: string) => normalizeString(description) === normalizedCustom;
    }, [customDescription]);

    const renderGridCells = () => {
        return personas.slice(0, Math.floor(personas.length / gridSize)*gridSize).map((persona, cellIndex) => {
            if (persona.Custom && !persona.url) {
                return (
                    <div
                        key="question-mark"
                        className={`${styles.personaCell} ${styles.questionMark}`}
                        onClick={() => error ? handlePersonaClick(cellIndex) : handleGenerateImage()}
                    >
                        {
                            generatingPreview ? <HourglassBottomIcon className={styles.hourGlass}/> :
                                error ? <span className={styles.previewError}>{error}</span> :
                                '?'
                        }
                    </div>
                );
            }
            return (
                <div
                    key={cellIndex}
                    className={`${styles.personaCell} ${isSelected(persona.Description) ? styles.selected : ''}`}
                    onClick={() => handlePersonaClick(cellIndex)}
                >
                    <img src={persona.url} alt={persona.Description} />
                </div>
            );
        });
    };

    const handlePersonaClick = (personaNumber: number) => {
        const persona = personas[personaNumber];
        const description = persona?.Description;
        setCustomDescription(description);
    };

    return (
        <Dialog
            open={true}
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    maxHeight: '90vh',
                    maxWidth: '90vw',
                    width: '100%',
                    height: '100%',
                    margin: '16px',
                },
                zIndex: 10000
            }}
        >
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <DialogTitle>{!isPrompt0 ? i18n('Edit Character') : i18n('Design Your Journal Character')}</DialogTitle>
            <DialogContent className={styles.dialogContent} sx={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column', paddingTop: '24px' }}>
                {isPrompt0 && (
                <div className={styles.prompt}>
                    {i18n('Describe your physical appearance in a few words, or draw inspiration by selecting one of the images below.')}<span> </span>
                    {i18n('EquiQuill will use this when panting your journal entries.')}
                </div>
                )}
                {!isPrompt0 && (<br/>)}
                <TextField
                    label={i18n('Character names (comma-separated)')}
                    placeholder={i18n('e.g., John, Johnny, Jo')}
                    value={characterName}
                    onChange={(e) => setCharacterName(e.target.value)}
                    sx={{ mb: 2 }}
                    fullWidth
                />
                <TextField
                    label={i18n('Character description (realistic or imaginative)')}
                    value={customDescription}
                    onChange={(e) => setCustomDescription(e.target.value)}
                    sx={{ mb: 2 }}
                    fullWidth
                />
                <div className={styles.scrollContainer}>
                    <div ref={containerRef} className={styles.matrixContainer + ' ' + styles['grid' + gridSize]}>
                        {renderGridCells()}
                    </div>
                </div>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" color="primary" onClick={() => onComplete({
                    ...originalCharacter,
                    name: characterName,
                    description: customDescription,
                })}>
                    {i18n('Continue')}
                </Button>
            </DialogActions>
            </div>
        </Dialog>
    );
};

export default PersonaSelectionMatrix;
