import React, { useContext, useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Character } from '../../../../shared/types';
import { EsApiContext } from '../../../../utils/equi-scrib-internal-api-context';
import styles from './styles.module.css';

interface CharacterCardProps {
    character: Character;
    onEdit: (character: Character) => void;
    onDelete: () => void;
}

export const CharacterCard: React.FC<CharacterCardProps> = ({ character, onEdit, onDelete }) => {
    const esApi = useContext(EsApiContext);
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('')

    useEffect(() => {
        const loadPreview = async () => {
            if (character.description) {
                setIsLoading(true);
                try {
                    const url = await esApi.doPersonaPreview(character.description);
                    setPreviewUrl(url);
                } catch (error:  any) {
                    const msg = (error.response?.data || 'An error occurred. Please try refreshing.')
                    setError(msg);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        loadPreview();
    }, [character.description, esApi]);

    return (
        <Card className={styles.card}>
            <div className={styles.mediaContainer}>
                {error ? (
                    <div className={styles.errorMessage}>{error}</div>
                ) : (
                    <CardMedia
                        component="img"
                        image={previewUrl}
                        alt={character.description}
                        onClick={() => {onEdit(character); setError('')}}
                        className={`${styles.media} ${isLoading ? styles.loading : ''}`}
                    />
                )}
                <div className={styles.actions}>
                    <IconButton 
                        aria-label="edit" 
                        onClick={() => {onEdit(character); setError('')}}
                        size="small"
                        className={styles.actionButton}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton 
                        aria-label="delete" 
                        onClick={onDelete}
                        size="small"
                        className={styles.actionButton}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </div>
            </div>
            <CardContent>
                <Typography variant="h6" component="div" className={styles.name}>
                    {character.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" className={styles.description}>
                    {character.description}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CharacterCard;
