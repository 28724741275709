import {useParams} from "react-router-dom";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ErrorIcon from '@mui/icons-material/Error';
import {useContext, useEffect, useRef, useState} from "react";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import {AlternateImage, generateReflectionId, MAX_ALTERNATIVES, Plan, seHasInput, seText} from "../../shared/types";
import styles from './styles.module.css'
import {SimpleI18NMessage, useSimpleI18n} from "../../utils/i18n";
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import Button from "@mui/material/Button";

const IMAGES_PER_MODEL = 4;
const ALL_MODEL = ['dall-e-3', 'dall-e-2', 'google', 'Leonardo-C', 'Leonardo-E'];

function getStyle(alt: AlternateImage): string {
    if (!alt || !alt.source) {
        return ''
    }
    let bs = '';
    switch (alt.source) {
        case 'dall-e-2':
            bs = 'd2';
            break;
        case 'dall-e-3':
            bs = 'd3';
            break;
        case 'google':
            bs = 'go';
            break;
        case 'Leonardo-C':
            bs = 'lc';
            break;
        case 'Leonardo-E':
            bs = 'le';
            break;
        default:
            bs = alt.source;
            break;
    }
    if (alt.style) {
        return `${bs}.${alt.style.substring(0, 3).toLowerCase()}`
    }
    return bs;
}

const AlternateImages = () => {
    const dateStr = useParams().date_and_seq || '';
    const esApi = useContext(EsApiContext)

    const [loadingStatus, setLoadingStatus] = useState("Loading...");
    const [imagePrompt, setImagePrompt] = useState("");
    const isStartingUp = useRef(false);
    const [imageUrl, setImageUrl] = useState("");
    const [error, setError] = useState("");
    const [models, setModels] = useState(ALL_MODEL);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [showModels, setIsShowModels] = useState(false);


    const [alts, setAlts] = useState([] as AlternateImage[]);
    const altsRef = useRef(alts);
    const [reflectionKey, setReflectionKey] = useState('')

    const requests = useRef([] as AbortController[]);


    async function startup() {
        if (isStartingUp.current) {
            return;
        }
        isStartingUp.current = true;
        setLoadingStatus("Logging in...");
        await esApi.login()
        setLoadingStatus("Getting quill entry...");
        const se = (await esApi.getScribEntries(dateStr))[0];
        setIsShowModels((await esApi.getSettings(false)).plan === Plan.FOUNDER);
        if (!se)
            throw new Error("No entries found for " + dateStr)
        if (!seHasInput(se))
            throw new Error("No input found for " + dateStr)
        setLoadingStatus("Getting current reflection...");
        const reflection = await esApi.getReflection(generateReflectionId(seText(se)))
        if (!reflection || !reflection.imageAltText) {
            throw new Error("No reflection found for " + dateStr)
        }
        setLoadingStatus("DONE");
        setReflectionKey(reflection.id);
        setImagePrompt(reflection.imageAltText);
        setImageUrl(reflection.image)
        refreshImages(reflection.imageAltText);
    }

    function cancelAllRequests() {
        requests.current.forEach(r => r.abort());
        requests.current = [];
    }

    function clearAllImages() {
        const images: AlternateImage[] = [];
        for (let i = 0; i < models.length * IMAGES_PER_MODEL; i++) {
            images.push({
                url: '',
                state: 'waiting',
                created: 0,
            })
        }
        setAlts(images);
        altsRef.current = images;
    }

    function processing(i: number) {
        const images = [...altsRef.current];
        images[i] = {
            url: '',
            state: 'processing',
            created: 0,
        };
        setAlts(images);
        altsRef.current = images;
    }

    function processed(number: number, img1: AlternateImage) {
        const images = [...altsRef.current];
        images[number] = img1;
        setAlts(images);
        altsRef.current = images;
    }

    async function generateAlternatives(prompt: string, model: string, startAt: number) {
        const cancelToken = esApi.getCancelToken();
        let lastImageUrl = ''
        for (let i = 0; i < MAX_ALTERNATIVES; i++) {
            processing(i + startAt);
            requests.current.push(cancelToken);
            try {
                const img1 = await esApi.createReflectionAlternative(prompt, model, lastImageUrl, forceRefresh, cancelToken);
                lastImageUrl = img1.url;
                img1.state = 'processed'
                processed(i + startAt, img1);
            } catch (e: any) {
                if (e.name === 'AbortError') {
                    // ignore
                } else {
                    processed(i + startAt, {
                        url: '',
                        state: 'error',
                        created: 0,
                        source: e.message,
                        cost: 0
                    });
                }
            }
        }
    }

    function refreshImages(imagePrompt: string) {
        clearAllImages();
        cancelAllRequests();
        setTimeout(() => {
            for (let i = 0; i < models.length; i++) {
                generateAlternatives(imagePrompt, models[i], i * IMAGES_PER_MODEL);
            }
        }, 1000);
    }

    const i18n = useSimpleI18n();

    useEffect(() => {
        startup().catch((e) => {
            setError(`${e}`)
        })
    }, [startup])

    function updateImagePrompt(prompt: string) {
        setImagePrompt(prompt)
    }

    if (error) {
        return <div>{error}</div>
    }

    async function setReflectionKeyImage(url: string) {
        await esApi.setPrimaryImage(reflectionKey, imagePrompt, url);
        window.location.reload()
    }


    return (
        <div className={styles.main}>
            <h1>{i18n("Alternate Images")}</h1>
            <div className={styles.prompt}>
                <TextField rows={5} fullWidth={true} multiline={true} value={imagePrompt}
                           onChange={(e) => updateImagePrompt(e.target.value)}></TextField>
                {/*<textarea rows={5} value={imagePrompt} onChange={(e) => updateImagePrompt(e.target.value)}></textarea>*/}
                <div>
                    {showModels && (
                        <>
                            <FormControlLabel control={
                                <Checkbox checked={forceRefresh} onChange={(e) => setForceRefresh(e.target.checked)}/>
                            } label={"Force Refresh"}/>
                            {
                                ALL_MODEL.map((model, idx) => (
                                    <FormControlLabel label={model}
                                                      control={
                                                          <Checkbox checked={models.indexOf(model) >= 0}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setModels([...models, model])
                                                                        } else {
                                                                            setModels(models.filter(m => m !== model))
                                                                        }
                                                                    }}
                                                                    inputProps={{'aria-label': 'controlled'}}
                                                          />
                                                      }
                                    />
                                ))}
                            )
                        </>
                    )}
                    <Button variant={'contained'} onClick={() => refreshImages(imagePrompt)}>{i18n("GO")}</Button>
                </div>

            </div>
            <div className={styles.images}>
                <div className={styles.imageWrapper + ' selected'}>
                    <img src={imageUrl} alt="primary"/>
                </div>
                {alts.map((alt, idx) => (
                    <div key={'img' + idx} className={styles.imageWrapper + ' ' + alt.state}
                         onClick={() => setReflectionKeyImage(alt.url)}>
                        {alt.state === "waiting" &&
                            <div className={styles.noImageDiv + ' waiting'}><HourglassEmptyIcon/></div>}
                        {alt.state === "processing" &&
                            <div className={styles.noImageDiv + ' processing'}><HourglassTopIcon/></div>}
                        {alt.state === "error" && <div className={styles.noImageDiv + ' error'}><ErrorIcon/></div>}
                        {alt.state === "processed" && <img src={alt.url}/>}
                        <div className={styles.labels}>
                            <div><span>{"Cmp:"}</span>{alt.cost}</div>
                            <div><span>{"Src:"}</span>{getStyle(alt)}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.status}><SimpleI18NMessage msg={loadingStatus}/></div>
        </div>
    )
}

export default AlternateImages;