import React from "react";
import {JoyRide} from "./components/tutorial";
import App from "./App";

const AppAugmentedJoyRide = () => {
    // start tutorial once logged in
    return (
        <>
            <App/>
            <JoyRide/>
        </>
    );
};

export default AppAugmentedJoyRide