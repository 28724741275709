import React, { useState } from 'react';
import { Grid, Paper, Fab, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import styles from './styles.module.css';
import { Character } from '../../../../shared/types';
import { PersonaSelectionMatrix } from '../../../personaselection';
import CharacterCard from '../charactercard';

export type CharacterFormProperties = {
    readonly characters: Character[],
    readonly onCharactersChanged: (newCharacters: Character[]) => void,
}

const CharacterForm: React.FC<CharacterFormProperties> = (props: CharacterFormProperties) => {
    const [editingCharacter, setEditingCharacter] = useState<{ index: number, character: Character } | null>(null);
    const [isAdding, setIsAdding] = useState(false);

    const handleAdd = () => {
        setIsAdding(true);
    };

    const handleEdit = (index: number, character: Character) => {
        setEditingCharacter({ index, character });
    };

    const handleDelete = (index: number) => {
        const newCharacters = [...props.characters];
        newCharacters.splice(index, 1);
        props.onCharactersChanged(newCharacters);
    };

    const handlePersonaSelectionComplete = (character: Character) => {
        if (editingCharacter !== null) {
            // Editing existing character
            const newCharacters = [...props.characters];
            newCharacters[editingCharacter.index] = character;
            props.onCharactersChanged(newCharacters);
            setEditingCharacter(null);
        } else {
            // Adding new character
            props.onCharactersChanged([...props.characters, character]);
            setIsAdding(false);
        }
    };

    return (
        <>
            <Grid container spacing={2} className={styles.grid}>
                {props.characters.map((character, index) => (
                    <Grid item key={index}>
                        <CharacterCard
                            character={character}
                            onEdit={(char) => handleEdit(index, char)}
                            onDelete={() => handleDelete(index)}
                        />
                    </Grid>
                ))}
                <Grid item>
                    <Box
                        className={styles.addCard}
                        onClick={handleAdd}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Fab color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Box>
                </Grid>
            </Grid>

            {(isAdding || editingCharacter !== null) && (
                <PersonaSelectionMatrix
                    isPrompt0={false}
                    character={editingCharacter?.character || { name: '', description: '' }}
                    onComplete={handlePersonaSelectionComplete}
                />
            )}
        </>
    );
};

export default CharacterForm;
