import {checksum, decompressLines} from "../utils";

export type Point = { x: number, y: number }
export type Line = { x0: number, y0: number, x1: number, y1: number }

export const DEFAULT_PAPER_SIZE = {width: 8.5, height: 11}

export const MAX_ENTRIES_PER_DAY = 4;
export const MIN_TEXT_LENGTH = 100;
export const MIN_LINES_LENGTH = MIN_TEXT_LENGTH * 5;
export const DPI = 150
export const MAX_WIDTH = 8.5 * DPI
export const MAX_HEIGHT = 11 * DPI * 2
export const UNIQUISH_CLIENT_ID_LENGTH = 6
export const MIN_X = -100
export const MAX_X = MAX_WIDTH
export const MIN_Y = -100
export const MAX_Y = MAX_HEIGHT
export const ENTRY_RE = /^20[2-9]\d[01]\d[0-3]\d(\.[0-3])?$/
export const ENTRY_RANGE_RE = /^20[2-9]\d[01]\d[0-3]\d(\.[0-3])?-20[2-9]\d[01]\d[0-3]\d(\.[0-3])?$/

export enum IDType {
    ROOT,
    ROOT_OR_CHILD,
    CHILD
}

export function isValidIdentifier(id: string) {
    return (id && /^[A-Za-z0-9]{4,100}$/.test(id))
}
export function checkIsValidIdentifier(id: string) {
    // must be 4 to 100 alphanumeric, otherwise arise an error
    if (!isValidIdentifier(id)) {
        throw new Error("Bad ID");
    }
}

export function checkIsValidId(id: string, idType: IDType = IDType.ROOT) {
    if (!id || !ENTRY_RE.test(id)) {
        // TODO P3: NLS
        throw new Error(`Invalid id: ${id}`)
    }
    switch (idType) {
        case IDType.ROOT:
            if (id.length > 8) {
                // TODO P3: NLS
                throw new Error(`Invalid id: ${id}`)
            }
            break;
        case IDType.ROOT_OR_CHILD:
            break;
        case IDType.CHILD:
            if (id.length !== 10) {
                // TODO P3: NLS
                throw new Error(`Invalid id: ${id}`)
            }
            break;

    }
}

// TODO P3: NLS
export const EMPTY_REFLECTION: Reflection = {
    scribEntryId: 'empty',
    id: `empty`,
    text: 'Nothing to reflect on',
    image: `https://storage.googleapis.com/equi-scrib-images/empty.png`,
    imageAltText: 'An empty reflection',
    lastUpdated: 0,
    created: 0,
}
export const TOO_SHORT_REFLECTION: Reflection = {
// TODO P3: NLS
    id: `more`,
    scribEntryId: 'more',
    text: 'Not enough text to reflect on',
    image: `https://storage.googleapis.com/equi-scrib-images/more.png`,
    imageAltText: 'Need more text',
    lastUpdated: 0,
    created: 0,
}

export function createEmptyScribEntry(yearmonthday: string, seq: number): ScribEntry {
    return {
        id: yearmonthday + '.' + seq,
        lines: '',
        ocrText: '',
        overriddenText: '',
        emojiUtf8: '?,?',
    } as ScribEntry
}

export const MAX_ALTERNATIVES = 4

export type Alternatives = {
    prompt: string,
    key: string,
    images: AlternateImage[]
}


export type AlternateImage = {
    url: string
    created: number
    isCensoredAlternative?: boolean
    state?: 'waiting'|'processing'|'processed'|'error'
    style?: string
    source?: 'dall-e-2' | 'dall-e-3' | 'Leonardo-C' | 'Leonardo-E' | 'google'
    cost?: number
}

export type Cryptable = {}

export type Textable = {
    ocrText: string,
    overriddenText: string
}

export type IPersistable = {
    id: string,
    created: number
    lastUpdated: number
}


export type SharedEntry  = IPersistable & {
    uid: string,
    scribEntryId: string,
    encryptedImageUrl: string,
    encryptedReflection: string
}

export type ScribEntry = Cryptable & Textable & IPersistable & {
    lines: string, // compressed
    language: string
    reflectionId?: string
    emojiUtf8: string
}

export type InappropriateContent = ScribEntry & Reflection & {
    inappropriateComment: string,
    reflector: string
}

export type VersionInfo = {
    version: string;
    buildDate: string
}

export type ScribSession = {
    id: string
    created: number
    lastAccessed: number
    uid: string
    agent: string
    user: ESUser
    ip: string
    // the language detected from the browser via headers.  We tend to ignore this and favor the user's language,
    // which is initially derived from the browser, but can be changed at anytime when the user creates journal
    // entries in a different language.
    language: string
    originalIp: string
    uniquishId: string
}

export type StravaUser = {
    stravaId: number
    esUid: string
}

export type ESUser = {
    readonly isAlpha?: boolean;
    readonly uid: string,
    readonly email: string,
    readonly displayName: string;
    readonly disabled: boolean;
    language?: string;
    readonly customClaims?: {
        [key: string]: any;
    };
}

export type DaySummary = {
    year: number
    month: number
    day: number
    entries: Array<EntrySummary>
}

export type EntrySummary = {
    id: string
    textHash: string
    summaryEmoji: string
    imageUrl: string
    svg?: string
}

export type Reflection = Cryptable & IPersistable & {
    scribEntryId: string
    text: string
    image: string // url
    imageAltText: string
    imageCost?: number
    style?: string
}

export type Character = {
    readonly name: String,
    readonly description: string
}

export enum Plan {
    FREE = "Free",
    LIFETIME = "Lifetime",
    FOUNDER = "Founder"
}

export type SubscriptionCheckoutSession = {
    readonly id: string
    readonly url: string
}

// items that we allow saving in our database
export const SETTINGS_PROPERTIES = ['uid', 'tutorialsCompleted', 'characters', 'pseudoE2eKeyVersion', 'trueE2eKeyVersion', 'newPseudoE2eKeyVersion', 'newTrueE2eKeyVersion', 'plan', 'planPurchaseToken']
export type UserSettings = {
    readonly characters: Character[]
    readonly pseudoE2eKey?: string
    readonly pseudoE2eKeyVersion: number
    readonly trueE2eKeyVersion?: number
    readonly newPseudoE2eKey?: string
    readonly newPseudoE2eKeyVersion?: number
    readonly newTrueE2eKeyVersion?: number
    readonly plan?: Plan
    readonly planPurchaseToken?: string[];
    readonly stravaInfo?: {
        readonly accessToken: string
        readonly accessTokenExpiry: number
        readonly refreshToken?: string
        readonly athleteId: number
    }
    tutorialsCompleted?: number[]
}

export type ProviderData = {
    displayName: string,
    email: string,
    photoURL?: string,
    providerId: string,
    uid: string,
}

export type ScribUser = {
    readonly disabled: boolean,
    readonly displayName: string,
    readonly email: string,
    readonly emailVerified: boolean,
    readonly language?: string
    readonly metadata: {
        creationTime: string,
        lastRefreshTime: string,
        lastSignInTime: string,
    }
    readonly providerData: ProviderData[],
    readonly photoURL?: string,
    readonly uid: string,
}


export function seHover(entry: ScribEntry) {
    return entry.emojiUtf8?.split(',')[0] || ''
}

export function seEmoji(entry: ScribEntry) {
    return entry.emojiUtf8?.split(',')[1] || ''
}

export function seText(entry: ScribEntry | Textable): string {
    return entry.overriddenText || entry.ocrText
}

export function seToSvg(entry: ScribEntry) {
    if (entry.overriddenText) {
        return createSvgWithWrappedText(entry.overriddenText, 200, 200)
    }
    return linesToSvg(decompressLines(entry.lines));
}


function createSvgWithWrappedText(text: string, maxWidth: number, maxHeight: number) {
    const words = text.split(' ');
    let currentLine = words[0];
    let fontSize = 20; // Start with a font size of 20
    let lineHeight = fontSize * 1.2; // Line height is 1.2 times the font size
    let lines = [];

    for (let i = 1; i < words.length; i++) {
        let word = words[i];
        if (currentLine.length + word.length < maxWidth / (fontSize * 0.6)) {
            currentLine += " " + word;
        } else {
            lines.push(currentLine);
            currentLine = word;
        }
    }
    lines.push(currentLine);

    // Check if text exceeds the maxHeight and reduce font size accordingly
    while (lines.length * lineHeight > maxHeight && fontSize > 5) {
        fontSize--;
        lineHeight = fontSize * 1.2;
        lines = [];
        currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
            let word = words[i];
            if (currentLine.length + word.length < maxWidth / (fontSize * 0.6)) {
                currentLine += " " + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        }
        lines.push(currentLine);
    }

    // Generate SVG with the processed lines
    let baseSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${maxWidth} ${maxHeight}">`;
    lines.forEach((line, index) => {
        baseSvg += `<text x="0" y="${lineHeight * (index + 1)}" font-size="${fontSize}" font-family="sans-serif">${line}</text>`;
    });
    baseSvg += `</svg>`;

    return baseSvg;
}


export function linesToSvg(lines: Array<Line>) {
    const maxWidth = Math.max(...lines.map(l => l.x1), ...lines.map(l => l.x0));
    const maxHeight = Math.max(...lines.map(l => l.y1), ...lines.map(l => l.y0));

    let baseSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${maxWidth} ${maxHeight}">`
        baseSvg += `<rect x="0" y="0" width="${maxWidth}" height="${maxHeight}" fill="white"/>
<path fill="none" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="${lines.map(line => `M${line.x0} ${line.y0} L${line.x1} ${line.y1}`).join(' ')}"/>`;
    return baseSvg + '</svg>';
}

export function generateReflectionId(text: string) {
    if (!text) {
        return EMPTY_REFLECTION.id;
    }
    let trimText = text;
    if (text.startsWith('e2eS0C0 ')) {
        trimText = trimText.substring(8)
        // base 64 decode the tet as well
        trimText = Buffer.from(trimText, 'base64').toString()
    } else {
        if (text.length < MIN_TEXT_LENGTH) {
            return TOO_SHORT_REFLECTION.id;
        }
    }
    trimText = trimText.toLowerCase().replaceAll(/[^a-z0-9]/g, '');
    return checksum(trimText);
}

export function seSmallImage(txt: string) {
    if (txt && !txt.endsWith("_small.png") && txt.endsWith(".png")) {
        return txt.replace('.png', '_small.png')
    } else {
        return txt;
    }
}

export function seHasInput(entry: ScribEntry) {
    return entry.lines?.length > MIN_LINES_LENGTH || !!entry.overriddenText
}

export function seCanReflect(entry: ScribEntry | { overriddenText: string, ocrText: string|Error }): boolean {
    if (entry.overriddenText && entry.overriddenText.length > MIN_TEXT_LENGTH) {
        return true;
    }
    if (entry.overriddenText) {
        return false;
    }
    if (typeof(entry.ocrText) === 'string' && entry.ocrText.length > MIN_TEXT_LENGTH) {
        return true;
    }
    return false;
}

export enum GetReflectionFireStrategy {
    ONLY_IF_EXISTS, // will retrieve from firestore, but will not create if not exists
    CREATE_ALL_IF_NOT_EXISTS, // will retrieve from firestore, but will create if not exists
    CREATE_PSY_ONLY_IF_NOT_EXISTS, // will retrieve from firestore, but will create if not exists
    CREATE_IMP_ONLY_IF_NOT_EXISTS, // will retrieve from firestore, but will create if not exists
    CREATE_IMG_ONLY_IF_NOT_EXISTS, // will retrieve from firestore, but will create if not exists
    ALWAYS_CREATE_ALL, // will always generate a new reflection, reflect on both the psychologist text, and image
    ALWAYS_CREATE_PSY_ONLY, // will always generate a new reflection, reflect on only the image
    ALWAYS_CREATE_IMP_ONLY, // will always generate a new reflection, reflect on only the image
    ALWAYS_CREATE_IMG_ONLY, // will always generate a new reflection, reflect on only the image
}

export function checkIsValidKey(id: string, minLen: number = 16, maxLen: number = 100, throwIfInvalid: boolean = true) {
// TODO P3: NLS
    if (id.length < minLen || id.length > maxLen || !/^[A-Za-z0-9]+$/.test(id)) {
        if (throwIfInvalid) {
            throw new Error("Bad ID");
        } else {
            return false;
        }
    }
    return true;
}